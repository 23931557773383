import { useRef, useState } from "react"
import logo from "/images/header/logo/sinnapi.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo"
import { Link, useLocation, useNavigate } from "react-router-dom"
import SideBarMobile from "../sidebars/SideBarMobile"
import CustomIcon from "../reusables/icons/CustomIcon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import SideBarDashboardMobile from "../sidebars/dashboards/SideBarDashboardMobile"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import {
  useLocalStorage,
  useOnClickOutside,
  useReadLocalStorage,
} from "usehooks-ts"
import { useAuth } from "../../services/auth/AuthContext"
import { FormProvider, useForm } from "react-hook-form"
import CustomSelect from "../reusables/CustomSelect"
import {
  useGetAllCategoriesQuery,
  useSearchListingsQuery,
} from "../../services/auth/api"
import { useAppDispatch } from "../../app/hooks"
import { setSearchResults } from "../../features/general/vendor_listings/searchSlice"
import SearchPlaces from "../reusables/map/SearchPlaces"

function Header() {
  const { width: screenWidth } = useWindowSize()
  const { data: categories = [] } = useGetAllCategoriesQuery()
  const [districtName, setDistrictName] = useState<string | undefined>()
  const [categoryName, setCategoryName] = useState<string | undefined>()
  const [latitude, setLatitude] = useState<number | undefined>()
  const [longitude, setLongitude] = useState<number | undefined>()
  const [, setRole] = useLocalStorage("role", "ROLE_USER")
  const [, setIsAuthenticated] = useLocalStorage("isAuthenticated", "false")
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const { refetch } = useSearchListingsQuery({
    categoryName: categoryName,
    latitude: latitude,
    longitude: longitude,
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleViewMoreClick = (
    route: any,
    dataType: "vendors" | "listings",
    state: any = null,
  ) => {
    navigate(route, { state: { dataType, ...state } })
  }
  const handleSearchNow = async () => {
    const { data: searchResults } = await refetch()
    searchResults && dispatch(setSearchResults({ listings: searchResults }))
    setCategoryName("")
    setDistrictName("")
    setLatitude(undefined)
    setLongitude(undefined)
    handleViewMoreClick("/listing-with-map", "listings", { fromSearch: true })
  }
  const location = useLocation().pathname
  const checkDashboardPath = (): boolean => {
    return (
      location.includes("/customer") ||
      location.includes("/vendor") ||
      location.includes("/admin")
    )
  }
  const checkIfMapPage = (): boolean => {
    if (location === "/listing-with-map") {
      return true
    } else {
      return false
    }
  }
  const logoutAuthContext = useAuth().logoutFunction
  const handleLogout = () => {
    logoutAuthContext()
    setIsAuthenticated("false")
    setRole("ROLE_USER")
  }
  const role = useReadLocalStorage("role")
  const isAuthenticated = useReadLocalStorage("isAuthenticated")
  const ref = useRef<HTMLDivElement>(null)
  const handler = () => {
    setIsDropDownOpen(false)
  }
  useOnClickOutside(ref, handler)
  const methods = useForm()
  return (
    <FormProvider {...methods}>
      <div className="bg-theme_primary py-4">
        <div className="container grid grid-rows-2 grid-cols-12 px-2 lg:grid-rows-1 xl:gap-x-2 items-center max-w-6xl mx-auto">
          <div className="pl-2 xl:pl-0 lg:row-start-1 col-span-2 md:col-span-1 flex flex-row justify-between items-center space-x">
            <div
              className={
                (checkDashboardPath() ? "lg:hidden" : "") + " xl:hidden"
              }
            >
              {checkDashboardPath() ? (
                <SideBarDashboardMobile />
              ) : (
                <SideBarMobile />
              )}
            </div>
            <Link to={"/"} className="">
              <img
                src={logo}
                alt="sinnapi"
                className="max-w-[180%] md:max-w-[150%] xl:max-w-[100%]"
              />
            </Link>
          </div>
          <div className="col-span-12 xl:col-start-2 xl:col-span-9 row-start-2 xl:row-start-1 xl:justify-self-center items-center mb-2 xl:ml-4 xl:mb-0 flex flex-row w-full">
            {!checkIfMapPage() && (
              <>
                <div className="w-full">
                  <CustomSelect
                    options={["", ...(categories as any)]}
                    placeholder={
                      screenWidth <= 1024
                        ? "Category"
                        : "Choose Vendor Category"
                    }
                    name="categories"
                    className="w-full text-xs text-gray-400 bg-gray-100 border-gray-300 rounded rounded-r-none"
                    onChange={(event: string) => setCategoryName(event)}
                  />
                </div>
                <div className="flex flex-row items-center w-[160%] md:w-full">
                  <SearchPlaces
                    placeholder={
                      screenWidth <= 1024
                        ? "Location"
                        : "Choose Vendor Location"
                    }
                    onChange={(lat, lng) => {
                      setLatitude(lat)
                      setLongitude(lng)
                    }}
                  />
                  <button
                    onClick={handleSearchNow}
                    className="bg-theme_secondary text-xs xl:text-sm py-2 text-white rounded-r px-4 whitespace-nowrap font-semibold font-theme_secondary_light"
                  >
                    Search Now
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="col-start-12 justify-self-end flex flex-row lg:space-x-2">
            <div className="relative">
              <button
                id="menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                className="flex flex-row space-x-2 items-center lg:bg-theme_secondary rounded py-2 px-2 text-white"
              >
                <FontAwesomeIcon icon={faUser} className="text-xs" />
                <div className="hidden lg:flex flex-row items-center space-x-1">
                  <span className="text-sm">Account</span>
                  <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
                </div>
              </button>
              <div
                className={
                  isDropDownOpen
                    ? "block absolute -right-6 z-50 w-28 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    : "hidden"
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex={-1}
                ref={ref}
              >
                <div
                  className="py-1 divide-y-2 divide-gray-200 font-theme_secondary_bold"
                  role="none"
                >
                  <div className="p-1">
                    <Link
                      to={
                        isAuthenticated === "true" && role === "ROLE_ADMIN"
                          ? "/admin"
                          : isAuthenticated === "true" &&
                            role === "ROLE_CUSTOMER"
                          ? "/customer"
                          : isAuthenticated === "true" && role === "ROLE_VENDOR"
                          ? "/vendor"
                          : "/login"
                      }
                      className="text-gray-700 hover:text-white block px-4 py-2 text-center uppercase text-xs hover:bg-theme_secondary rounded"
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-0"
                      onClick={() => setIsDropDownOpen(false)}
                    >
                      {isAuthenticated === "true" &&
                      (role === "ROLE_ADMIN" ||
                        role === "ROLE_CUSTOMER" ||
                        role === "ROLE_VENDOR")
                        ? "Dashboard"
                        : "Sign In"}
                    </Link>
                  </div>
                  <div className="p-1">
                    <Link
                      to={
                        isAuthenticated === "true" && role === "ROLE_ADMIN"
                          ? "/admin/profile"
                          : isAuthenticated === "true" &&
                            role === "ROLE_CUSTOMER"
                          ? "/customer/profile"
                          : isAuthenticated === "true" && role === "ROLE_VENDOR"
                          ? "/vendor/profile"
                          : "/vendor-signup"
                      }
                      className="text-gray-700 hover:text-white block px-4 py-2 text-xs hover:bg-theme_secondary rounded"
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-1"
                      onClick={() => setIsDropDownOpen(false)}
                    >
                      <CustomIcon
                        type={"faUser" as unknown as IconDefinition}
                        className="text-xs px-1 -ml-4"
                      />
                      {isAuthenticated === "true" &&
                      (role === "ROLE_ADMIN" ||
                        role === "ROLE_CUSTOMER" ||
                        role === "ROLE_VENDOR")
                        ? "Profile"
                        : "Vendor"}
                    </Link>
                  </div>
                  <div className="p-1">
                    <Link
                      to={isAuthenticated === "false" ? "/customer-signup" : ""}
                      className={`${
                        isAuthenticated === "true" && "text-red-500"
                      } text-gray-700 hover:text-white block px-4 py-2 text-xs hover:bg-theme_secondary rounded`}
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-2"
                      onClick={() => {
                        isAuthenticated === "false"
                          ? setIsDropDownOpen(false)
                          : handleLogout()
                      }}
                    >
                      {isAuthenticated === "false" && (
                        <CustomIcon
                          type={"faUser" as unknown as IconDefinition}
                          className="text-xs px-1 -ml-4"
                        />
                      )}
                      {isAuthenticated === "true" &&
                      (role === "ROLE_ADMIN" ||
                        role === "ROLE_CUSTOMER" ||
                        role === "ROLE_VENDOR")
                        ? "Logout"
                        : "Customer"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Link
              to={"/contact-us"}
              className="flex flex-row items-center justify-center lg:bg-theme_secondary hover:bg-theme_secondary rounded px-2 space-x-1 text-white"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
              <span className="text-xs hidden lg:inline">Help</span>
            </Link>
          </div>
        </div>
      </div>
    </FormProvider>
  )
}

export default Header
