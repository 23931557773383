import { lazy } from "react"
import ListingSingular from "./ListingSingular"
import ListingWithMap from "./ListingWithMap"

// const LazyListingWithMap = lazy(() => import("./ListingWithMap"))
const LazyListingSingular = lazy(() => import("./ListingSingular"))

export const VendorListings = {
  LazyListingSingular,
  ListingWithMap,
}
