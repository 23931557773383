import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://secure.3gdirectpay.com/API/v6/",
  }),
  endpoints: (builder) => ({
    createToken: builder.mutation<any, any>({
      query(paymentPayload) {
        return {
          url: "",
          method: "POST",
          headers: {
            "Content-Type": "application/xml",
          },
          body: paymentPayload,
        }
      },
    }),
  }),
})

export const { useCreateTokenMutation } = paymentApi
