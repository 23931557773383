import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import mainLayoutReducer from "../layouts/main/mainLayoutSlice"
import sideBarsSlice from "../layouts/sidebars/sideBarsSlice"
import mapSlice from "../layouts/reusables/map/mapSlice"
import vendorSlice from "../features/vendor/vendorSlice"
import authSlice from "../services/auth/authSlice"
import { api } from "../services/auth/api"
import { paymentApi } from "../services/payment/api"
import searchSlice from "../features/general/vendor_listings/searchSlice"
import customerSlice from "../features/customer/customerSlice"
import adminSlice from "../features/admin/adminSlice"
import { placesApi } from "../services/places/api"

export const store = configureStore({
  reducer: {
    auth: authSlice,
    [api.reducerPath]: api.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [placesApi.reducerPath]: placesApi.reducer,
    counter: counterReducer,
    mainLayout: mainLayoutReducer,
    sideBar: sideBarsSlice,
    map: mapSlice,
    vendor: vendorSlice,
    customer: customerSlice,
    admin: adminSlice,
    searchResults: searchSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      paymentApi.middleware,
      placesApi.middleware,
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
