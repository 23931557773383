import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import logo from "/images/footer/logo/logo.png"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch } from "../../app/hooks"
import { setSearchResults } from "../../features/general/vendor_listings/searchSlice"
import { useLazySearchListingsQuery } from "../../services/auth/api"
import { footerCategories, footerDistricts } from "../../utils/data"
import NewsLetter from "./NewsLetter"

function Footer() {
  const [trigger] = useLazySearchListingsQuery()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleCategoryClick = (
    route: any,
    dataType: "vendors" | "listings",
    state: any = null,
  ) => {
    navigate(route, { state: { dataType, ...state } })
  }
  const handleClick = async ({
    categoryName,
    districtName,
  }: {
    categoryName?: string
    districtName?: string
  }) => {
    if (categoryName) {
      await trigger({ categoryName: categoryName })
        .unwrap()
        .then((response: any[]) => {
          dispatch(setSearchResults({ listings: response }))
          handleCategoryClick("/listing-with-map", "listings", {
            fromSearch: true,
          })
        })
        .catch((error: any) => {
          toast.warn(error.data.error)
        })
    }
    if (districtName) {
      await trigger({ districtName: districtName })
        .unwrap()
        .then((response: any[]) => {
          dispatch(setSearchResults({ listings: response }))
          handleCategoryClick("/listing-with-map", "listings", {
            fromSearch: true,
          })
        })
        .catch((error: any) => {
          toast.warn(error.data.error)
        })
    }
  }
  return (
    <div className="bg-theme_black text-white font-theme_secondary_light md:h-96">
      <div className="grid grid-cols-1 md:grid-cols-9 max-md:gap-y-10 max-lg:px-8 max-lg:py-6 content-center md:justify-items-center max-w-7xl mx-auto h-full">
        <div className="col-span-1 md:max-lg:px-3 lg:max-xl:pl-3 w-32 md:max-lg:w-36 lg:w-auto">
          <div className="">
            <img src={logo} alt="sinnapi" />
          </div>
          <div className="text-xs">
            At Sinnapi, our purpose is to help people find great online network
            connecting event suppliers
          </div>
          <div className="mt-3">
            <button
              onClick={() => navigate("/about-us")}
              className="text-white text-sm font-theme_secondary_bold bg-theme_secondary hover:bg-theme_primary rounded-md px-3 py-2"
            >
              Know More
            </button>
          </div>
        </div>
        <div className="hidden md:block col-span-1 md:col-span-2">
          <h3 className="font-theme_secondary_bold">Categories</h3>
          <ul className="text-gray-400 ml-4 mt-4 space-y-3 text-sm list-image-[url('/images/footer/chevron-right-solid.svg')]">
            {footerCategories.map((category, i) => (
              <li key={i} className="hover:text-theme_secondary">
                <div
                  onClick={() => handleClick({ categoryName: category.title })}
                  className="cursor-pointer"
                >
                  {category.title}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden md:block col-span-1 md:col-span-2">
          <h3 className="font-theme_secondary_bold">Locations</h3>
          <ul className="text-gray-400 ml-4 mt-4 space-y-3 text-sm list-image-[url('/images/footer/chevron-right-solid.svg')]">
            {footerDistricts.map((district, i) => (
              <li key={i} className="hover:text-theme_secondary">
                <div
                  onClick={() => handleClick({ districtName: district })}
                  className="cursor-pointer"
                >
                  {district}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="font-theme_secondary_light col-span-1 md:col-span-2 md:max-lg:px-4">
          <h3 className="font-theme_secondary_bold">Contact Us</h3>
          <div className="text-gray-400 text-sm">
            <p>TechBuzz Hub</p>
            <p>1st Floor</p>
            <p>Ntinda, Kampala - Uganda</p>
          </div>
          <div className="text-gray-400 my-2 text-sm hover:text-theme_secondary">
            <a href="tel:+256 700 988931">Call: +256 700 988931</a>
          </div>
          <div className="text-gray-400 my-2 text-sm hover:text-theme_secondary">
            <a href="mailto:info@sinnapi.com">Mail : info@sinnapi.com</a>
          </div>
          <div className="space-x-1">
            {/* <Link to={"/"}>
              <FontAwesomeIcon
                icon={faFacebookF}
                className="bg-gray-600 hover:bg-theme_secondary py-1 px-2 text-sm"
              />
            </Link> */}
            <Link
              to={"https://x.com/sinnapi__?s=21"}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faXTwitter}
                className="bg-gray-600 hover:bg-theme_secondary p-1 text-sm"
              />
            </Link>
            <Link
              to={"https://www.instagram.com/sinnapiug?igsh=Z25vbWI5N2k5MXQx"}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="bg-gray-600 hover:bg-theme_secondary p-1 text-sm"
              />
            </Link>
            <Link
              to={
                "https://www.linkedin.com/feed/update/urn:li:activity:7146390720264781824?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7146390720264781824%29"
              }
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faLinkedinIn}
                className="bg-gray-600 hover:bg-theme_secondary p-1 text-sm"
              />
            </Link>
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 flex flex-col">
          <div>
            <h3 className="font-theme_secondary_bold">Newsletter</h3>
          </div>
          <div className="text-gray-400 text-xs mt-8">
            Subscribe to our newsletter to receive exclusive offers and events
            tips.
          </div>
          <div className="mt-4">
            <NewsLetter />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
