import React, { useEffect, useRef, useState } from "react"

function ImageCustom({
  src,
  blurredImg,
  className,
  classNameDiv,
  alt,
}: {
  src: string
  blurredImg: string
  className?: string
  classNameDiv?: string
  alt?: string
}) {
  const [imageLoaded, setImageLoaded] = useState(true)
  const blurredDiv = useRef(null)
  const img = useRef<HTMLImageElement>(null)

  // useEffect(() => {
  //   let intervalId: NodeJS.Timeout

  //   const checkImageLoading = () => {
  //     if (img.current?.complete) {
  //       //Delay the setting of imageLoaded by 1000 milliseconds (1 second)
  //       setTimeout(() => {
  //         setImageLoaded(true)
  //       }, 50)
  //       // setImageLoaded(true)

  //       clearInterval(intervalId)
  //     }
  //   }

  //   intervalId = setInterval(checkImageLoading, 100)

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [])

  return (
    <div
      className={`bg-[url('/images/placeholder-light.png')] bg-no-repeat bg-cover bg-center before:content-[""] before:absolute before:inset-0 before:opacity-0 before:animate-pulse ${
        imageLoaded ? "before:content-none" : ""
      } w-full ${classNameDiv}`}
      ref={blurredDiv}
    >
      <img
        src={imageLoaded ? src : "/images/placeholder-light.png"}
        alt={alt ? alt : "lazy-loaded"}
        loading="eager"
        className={`object-cover object-center opacity-0 transition-opacity duration-500 ${
          imageLoaded ? "opacity-100" : ""
        } ${className}`}
        ref={img}
      />
    </div>
  )
}

export default ImageCustom
