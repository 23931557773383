import React from "react"
import CustomIcon from "./icons/CustomIcon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

interface SpinnerProps {
  size?: number
  className?: string
}

const Spinner: React.FC<SpinnerProps> = ({ size = 4, className }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
      <div
        className={`inline-block animate-spin ${className}`}
        style={{ width: `${size}rem`, height: `${size}rem` }}
      >
        <CustomIcon
          type={"faCircleNotch" as unknown as IconDefinition}
          className="w-full h-full"
        />
      </div>
    </div>
  )
}

export default Spinner
