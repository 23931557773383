import { GeneralComponents } from "../../../features/general"

interface BaseRoute {
  path: string
  component: React.ComponentType<any>
}

interface ListingSingularRoute extends BaseRoute {
  path: "/listing-singular/:listingId"
}

interface ListingWithMapRoute extends BaseRoute {
  path: "/listing-with-map"
  dataType: "listings"
}

type GeneralRoute =
  | (ListingSingularRoute & { dataType?: never })
  | ListingWithMapRoute
  | (BaseRoute & { dataType?: never })

export const GeneralRoutes: GeneralRoute[] = [
  {
    path: "",
    component: GeneralComponents.LazyHome,
  },
  {
    path: "/listing-with-map",
    component: GeneralComponents.VendorListings.ListingWithMap,
    dataType: "listings",
  },
  {
    path: "/listing-singular/:listingId",
    component: GeneralComponents.VendorListings.LazyListingSingular,
  },
  {
    path: "/vendors",
    component: GeneralComponents.LazyVendors,
  },
  {
    path: "/contact-us",
    component: GeneralComponents.LazyContact,
  },
  {
    path: "/login",
    component: GeneralComponents.LazyLogin,
  },
  {
    path: "/customer-signup",
    component: GeneralComponents.LazyCustomerSignUp,
  },
  {
    path: "/vendor-signup",
    component: GeneralComponents.LazyVendorSignUp,
  },
  {
    path: "/admin-login",
    component: GeneralComponents.LazyAdminLogin,
  },
  {
    path: "/terms-conditions",
    component: GeneralComponents.LazyTermsAndConditions,
  },
  {
    path: "/forgot-password",
    component: GeneralComponents.LazyForgotPassword,
  },
  {
    path: "/reset-password",
    component: GeneralComponents.LazyResetPassword,
  },
  {
    path: "/about-us",
    component: GeneralComponents.LazyAboutUs,
  },
  {
    path: "/data-protection-and-guidelines",
    component: GeneralComponents.LazyDataProtectAndGuidelines,
  },
]
