import { createApi } from "@reduxjs/toolkit/dist/query/react"
import baseQuerywithReauth from "./baseQuerywithReauth"
import {
  AdminProfileUpdate,
  BusinessProfileUpdate,
  CategoriesAdmin,
  CategoriesPublic,
  Contact,
  CreateListingData,
  CreateSocialMediaLinkData,
  CustomerProfileUpdate,
  CustomerRegister,
  CustomersAdmin,
  LoginData,
  LoginResponse,
  PaymentsAdmin,
  Ratings,
  RequestPricing,
  ReviewComment,
  Reviews,
  VendorRegisterData,
  VendorsAdmin,
} from "../../utils/types"
import { AdminState } from "../../features/admin/adminSlice"

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuerywithReauth,
  tagTypes: [
    "Listings",
    "BusinessData",
    "SingleVendor",
    "SocialMediaLinks",
    "CurrentVendor",
    "AllVendorsAdmin",
    "ListingsAdmin",
    "CategoriesAdmin",
    "CategoriesPublic",
    "SingleListing",
    "CustomerListingStatus",
    "AllCustomerListings",
    "CurrentCustomer",
    "CustomersAdmin",
    "CurrentAdmin",
  ],
  endpoints: (builder) => ({
    vendorLogin: builder.mutation<LoginResponse, LoginData>({
      query(loginData) {
        return {
          url: "vendor/login",
          method: "POST",
          body: loginData,
        }
      },
      transformResponse: (response: { data: { roles: LoginResponse } }) =>
        response.data.roles,
      invalidatesTags: ["Listings", "BusinessData", "CurrentVendor"],
    }),
    vendorRegister: builder.mutation<any, VendorRegisterData>({
      query(vendorRegisterData) {
        const formData = new FormData()
        formData.append("email", vendorRegisterData.email)
        formData.append("fullName", vendorRegisterData.fullName)
        formData.append("phoneNumber", vendorRegisterData.phoneNumber)
        formData.append("password", vendorRegisterData.password)
        formData.append("businessName", vendorRegisterData.businessName)
        formData.append("categoryName", vendorRegisterData.categoryName)
        formData.append("address", vendorRegisterData.address)
        formData.append("nationalId", vendorRegisterData.nationalId)
        formData.append(
          "certificateOfIncorporation",
          vendorRegisterData.certificateOfIncorporation,
        )
        if (vendorRegisterData.transactionToken) {
          formData.append(
            "transactionToken",
            vendorRegisterData.transactionToken,
          )
        }
        if (vendorRegisterData.transactionId) {
          formData.append("transactionId", vendorRegisterData.transactionId)
        }
        return {
          url: `vendor/register`,
          method: "POST",
          body: formData,
          formData: true,
        }
      },
    }),
    customerRegister: builder.mutation<any, CustomerRegister>({
      query(customerRegisterData) {
        const formData = new FormData()
        formData.append("email", customerRegisterData.email)
        formData.append("fullName", customerRegisterData.fullName)
        formData.append("phoneNumber", customerRegisterData.phoneNumber)
        formData.append("password", customerRegisterData.password)
        return {
          url: "customer/register",
          method: "POST",
          body: formData,
          formData: true,
        }
      },
    }),
    customerLogin: builder.mutation<LoginResponse, LoginData>({
      query(loginData) {
        return {
          url: "customer/login",
          method: "POST",
          body: loginData,
        }
      },
      transformResponse: (response: { data: { roles: LoginResponse } }) =>
        response.data.roles,
    }),
    adminLogin: builder.mutation<LoginResponse, LoginData>({
      query(loginData) {
        return {
          url: "admin/login",
          method: "POST",
          body: loginData,
        }
      },
      transformResponse: (response: { data: { roles: LoginResponse } }) =>
        response.data.roles,
    }),
    updateAdminProfile: builder.mutation<any, AdminProfileUpdate>({
      query(data) {
        const formData = new FormData()
        if (data.email) {
          formData.append("email", data.email)
        }
        if (data.name) {
          formData.append("name", data.name)
        }
        if (data.phone) {
          formData.append("phone", data.phone)
        }
        if (data.profileImage) {
          formData.append("profileImage", data.profileImage)
        }
        return {
          url: "admin/update-profile",
          method: "POST",
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ["CurrentAdmin"],
    }),
    getCurrentAdmin: builder.query<AdminState, void>({
      query() {
        return {
          url: "admin/get-current-admin",
          method: "GET",
        }
      },
      providesTags: ["CurrentAdmin"],
    }),
    updateCustomerProfile: builder.mutation<any, CustomerProfileUpdate>({
      query(customerProfileUpdate) {
        const formData = new FormData()
        if (customerProfileUpdate.email) {
          formData.append("email", customerProfileUpdate.email)
        }
        if (customerProfileUpdate.name) {
          formData.append("name", customerProfileUpdate.name)
        }
        if (customerProfileUpdate.phone) {
          formData.append("phone", customerProfileUpdate.phone)
        }
        if (customerProfileUpdate.address) {
          formData.append("address", customerProfileUpdate.address)
        }
        if (customerProfileUpdate.about) {
          formData.append("about", customerProfileUpdate.about)
        }
        if (customerProfileUpdate.profileImage) {
          formData.append("profileImage", customerProfileUpdate.profileImage)
        }
        return {
          url: "customer/update-customer-profile",
          method: "POST",
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ["CurrentCustomer"],
    }),
    getCurrentCustomer: builder.query<any, void>({
      query() {
        return {
          url: "customer/get-current-customer",
          method: "GET",
        }
      },
      providesTags: ["CurrentCustomer"],
    }),
    updateBusinessProfile: builder.mutation<any, BusinessProfileUpdate>({
      query(businessProfileUpdate) {
        const formData = new FormData()
        if (
          businessProfileUpdate.galleryImages !== undefined &&
          Array.isArray(businessProfileUpdate.galleryImages)
        ) {
          businessProfileUpdate.galleryImages.forEach((galleryImage, index) => {
            if (galleryImage instanceof File) {
              formData.append(`galleryImages[${index}]`, galleryImage)
            }
          })
        } else {
          if (businessProfileUpdate.galleryImages instanceof File) {
            formData.append(
              "galleryImages",
              businessProfileUpdate.galleryImages,
            )
          }
        }
        if (businessProfileUpdate.nationalId) {
          formData.append("nationalId", businessProfileUpdate.nationalId)
        }
        if (businessProfileUpdate.certificateOfIncorporation) {
          formData.append(
            "certificateOfIncorporation",
            businessProfileUpdate.certificateOfIncorporation,
          )
        }

        formData.append(
          "vendorEmailAddress",
          businessProfileUpdate.vendorEmailAddress
            ? businessProfileUpdate.vendorEmailAddress
            : "",
        )

        formData.append(
          "vendorPhoneNumber",
          businessProfileUpdate.vendorPhoneNumber
            ? businessProfileUpdate.vendorPhoneNumber
            : "",
        )

        formData.append(
          "businessName",
          businessProfileUpdate.businessName
            ? businessProfileUpdate.businessName
            : "",
        )
        formData.append(
          "businessDescription",
          businessProfileUpdate.businessDescription
            ? businessProfileUpdate.businessDescription
            : "",
        )
        formData.append(
          "businessWebsite",
          businessProfileUpdate.businessWebsite
            ? businessProfileUpdate.businessWebsite
            : "",
        )
        formData.append(
          "latitude",
          businessProfileUpdate.latitude
            ? `${businessProfileUpdate.latitude}`
            : "0",
        )
        formData.append(
          "longitude",
          businessProfileUpdate.longitude
            ? `${businessProfileUpdate.longitude}`
            : "0",
        )
        if (businessProfileUpdate.businessVideo) {
          formData.append("businessVideo", businessProfileUpdate.businessVideo)
        }
        formData.append(
          "brandImage",
          businessProfileUpdate.brandImage
            ? businessProfileUpdate.brandImage
            : "",
        )
        formData.append(
          "profileImage",
          businessProfileUpdate.profileImage
            ? businessProfileUpdate.profileImage
            : "",
        )
        if (businessProfileUpdate.district) {
          formData.append("districtName", businessProfileUpdate.district)
        }
        return {
          url: `vendor/update-business-profile`,
          method: "POST",
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ["BusinessData", "SingleVendor", "CurrentVendor"],
    }),
    updateBrandImage: builder.mutation<any, { brandImage: File }>({
      query(brandImage) {
        const formData = new FormData()
        formData.append("brandImage", brandImage.brandImage)
        return {
          url: "vendor/update-business-profile",
          method: "POST",
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ["BusinessData", "SingleVendor", "CurrentVendor"],
    }),
    getVendorListings: builder.query<any[], void>({
      query() {
        return {
          url: "vendor/all-listings",
          method: "GET",
        }
      },
      providesTags: ["Listings"],
    }),
    getBusinessData: builder.query<any, void>({
      query() {
        return {
          url: "vendor/get-business-data",
          method: "GET",
        }
      },
      providesTags: ["BusinessData"],
    }),
    getSingleVendor: builder.query<any, any>({
      query(vendorId) {
        return {
          url: `public/vendors/${vendorId}`,
          method: "GET",
          credentials: "omit",
        }
      },
    }),
    createListing: builder.mutation<any, CreateListingData>({
      query(createListingData) {
        const formData = new FormData()
        if (createListingData.categoryName) {
          formData.append("categoryName", createListingData.categoryName)
        }
        if (createListingData.title) {
          formData.append("title", createListingData.title)
        }
        if (createListingData.districtName) {
          formData.append("districtName", createListingData.districtName)
        }
        if (createListingData.address) {
          formData.append("address", createListingData.address)
        }
        if (createListingData.latitude && createListingData.latitude !== null) {
          formData.append("latitude", `${createListingData.latitude}`)
        }
        if (
          createListingData.longtitude &&
          createListingData.longtitude !== null
        ) {
          formData.append("longitude", `${createListingData.longtitude}`)
        }
        if (createListingData.details && createListingData.details !== null) {
          formData.append("details", createListingData.details)
        }
        if (createListingData.price && createListingData.price !== null) {
          formData.append("price", `${createListingData.price}`)
        }
        if (createListingData.listingVideo && createListingData !== null) {
          formData.append("listingVideo", createListingData.listingVideo)
        }
        if (createListingData.amenities) {
          if (createListingData.amenities.length > 0) {
            const amenitiesString = createListingData.amenities.join(",")
            formData.append(`amenities`, amenitiesString)
          }
        }
        if (createListingData.featuredImage) {
          formData.append("featuredImage", createListingData.featuredImage)
        }
        if (createListingData.featuredImage) {
          formData.append("featuredImage", createListingData.featuredImage)
        }
        if (createListingData.listingImages) {
          createListingData.listingImages.forEach((image, index) => {
            formData.append(`listingImages[${index}]`, image)
          })
        }
        return {
          url: "vendor/add-listing",
          method: "POST",
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ["Listings"],
    }),
    updateListing: builder.mutation<any, CreateListingData>({
      query(updateListingData) {
        const formData = new FormData()
        if (
          updateListingData.listingId &&
          updateListingData.listingId !== null
        ) {
          formData.append("listingId", `${updateListingData.listingId}`)
        }
        if (updateListingData.categoryName) {
          formData.append("categoryName", updateListingData.categoryName)
        }
        if (updateListingData.title) {
          formData.append("title", updateListingData.title)
        }
        if (updateListingData.districtName) {
          formData.append("districtName", updateListingData.districtName)
        }
        if (updateListingData.address) {
          formData.append("address", updateListingData.address)
        }
        if (updateListingData.latitude && updateListingData.latitude !== null) {
          formData.append("latitude", `${updateListingData.latitude}`)
        }
        if (
          updateListingData.longtitude &&
          updateListingData.longtitude !== null
        ) {
          formData.append("longitude", `${updateListingData.longtitude}`)
        }
        if (updateListingData.details && updateListingData.details !== null) {
          formData.append("details", updateListingData.details)
        }
        if (updateListingData.price && updateListingData.price !== null) {
          formData.append("price", `${updateListingData.price}`)
        }
        if (updateListingData.listingVideo && updateListingData !== null) {
          formData.append("listingVideo", updateListingData.listingVideo)
        }
        if (updateListingData.amenities) {
          if (updateListingData.amenities.length > 0) {
            formData.append(
              `amenities`,
              JSON.stringify(updateListingData.amenities),
            )
          }
        }
        if (updateListingData.featuredImage) {
          formData.append("featuredImage", updateListingData.featuredImage)
        }
        if (updateListingData.listingImages) {
          updateListingData.listingImages.forEach((image, index) => {
            formData.append(`listingImages[${index}]`, image)
          })
        }
        return {
          url: `vendor/update-listing/${updateListingData.listingId}`,
          method: "POST",
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ["Listings", "SingleListing"],
    }),
    deleteListing: builder.mutation<any, number>({
      query(listingId) {
        return {
          url: `vendor/delete-listing/${listingId}`,
          method: "DELETE",
        }
      },
      invalidatesTags: ["Listings"],
    }),
    getListingsAdmin: builder.query<any[], void>({
      query() {
        return {
          url: "admin/listings",
          method: "GET",
        }
      },
      providesTags: ["ListingsAdmin"],
    }),
    updateListingStatusAdmin: builder.mutation<
      any,
      {
        listingId: number
        listingStatus: "DRAFT" | "PENDING" | "PUBLISH" | "REMOVED",
        listingComment: string
      }
    >({
      query({listingId, listingStatus, listingComment}) {
        return {
          url: "admin/update-listing-status",
          method: "POST",
          body: {listingId, listingStatus, listingComment},
        }
      },
      invalidatesTags: ["ListingsAdmin", "Listings"],
    }),
    getSingleListing: builder.query<any, { listingId: number }>({
      query(listingId) {
        return {
          url: `public/listing`,
          method: "GET",
          credentials: "omit",
          params: listingId,
        }
      },
      providesTags: ["SingleListing"],
    }),
    createSocialMediaLink: builder.mutation<any, CreateSocialMediaLinkData>({
      query(data) {
        return {
          url: "vendor/add-social-media-link",
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["SocialMediaLinks"],
    }),
    getSocialMediaLinks: builder.query<CreateSocialMediaLinkData[][], void>({
      query() {
        return {
          url: "vendor/get-social-media-links",
          method: "GET",
        }
      },
      providesTags: ["SocialMediaLinks"],
    }),
    editSocialMediaLink: builder.mutation<
      any,
      { socialMediaLinkId: number; title: string; link: string }
    >({
      query(data) {
        return {
          url: `vendor/edit-social-media-link/${data.socialMediaLinkId}`,
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["SocialMediaLinks"],
    }),
    getVendorReviews: builder.query<any[], void>({
      query() {
        return {
          url: "vendor/get-reviews",
          method: "GET",
        }
      },
    }),
    addReviewComment: builder.mutation<any, ReviewComment>({
      query(data) {
        return {
          url: "vendor/add-review-comment",
          method: "POST",
          body: data,
        }
      },
    }),
    getVendorRatings: builder.query<any, void>({
      query() {
        return {
          url: "vendor/get-ratings",
          method: "GET",
        }
      },
    }),
    getCurrentVendor: builder.query<any, void>({
      query() {
        return {
          url: "vendor/get-current-vendor",
          method: "GET",
        }
      },
      providesTags: ["CurrentVendor"],
    }),
    getAllVendorsAdmin: builder.query<VendorsAdmin[], void>({
      query() {
        return {
          url: "admin/vendors",
          method: "GET",
        }
      },
      transformResponse: (response: VendorsAdmin[]) => {
        return response.sort((a, b) => b.vendorId - a.vendorId)
      },
      providesTags: ["AllVendorsAdmin"],
    }),
    activateVendorAccount: builder.mutation<any, any>({
      query(data) {
        return {
          url: "admin/activate-vendor-account",
          method: "PUT",
          body: data,
        }
      },
      invalidatesTags: ["AllVendorsAdmin"],
    }),
    deactivateVendorAccount: builder.mutation<any, any>({
      query(data) {
        return {
          url: "admin/deactivate-vendor-account",
          method: "PUT",
          body: data,
        }
      },
      invalidatesTags: ["AllVendorsAdmin"],
    }),
    approveVendorNationalId: builder.mutation<any, any>({
      query(data) {
        return { url: "admin/approve-vendor-id", method: "PUT", body: data }
      },
      invalidatesTags: ["AllVendorsAdmin"],
    }),
    rejectVendorNationalId: builder.mutation<any, any>({
      query(data) {
        return {
          url: "admin/reject-vendor-id",
          method: "PUT",
          body: data,
        }
      },
      invalidatesTags: ["AllVendorsAdmin"],
    }),
    approveVendorCertificateOfIncorporation: builder.mutation<any, any>({
      query(data) {
        return {
          url: "admin/approve-vendor-certificate",
          method: "PUT",
          body: data,
        }
      },
      invalidatesTags: ["AllVendorsAdmin"],
    }),
    rejectVendorCertificateOfIncorporation: builder.mutation<any, any>({
      query(data) {
        return {
          url: "admin/reject-vendor-certificate",
          method: "PUT",
          body: data,
        }
      },
      invalidatesTags: ["AllVendorsAdmin"],
    }),
    vendorPayment: builder.mutation<any, void>({
      query() {
        return {
          url: "public/make-payment",
          method: "POST",
          credentials: "omit",
        }
      },
    }),
    sendPhoneOTp: builder.mutation<any, { phoneNumber: string }>({
      query(phoneNumber) {
        return {
          url: "vendor/send-phone-otp",
          method: "POST",
          body: phoneNumber,
        }
      },
    }),
    sendEmailOTp: builder.mutation<any, void>({
      query() {
        return {
          url: "vendor/send-email-otp",
          method: "POST",
        }
      },
    }),
    verifyEmailOtp: builder.mutation<any, { otp: number }>({
      query(otp) {
        return {
          url: "vendor/verify-email-otp",
          method: "POST",
          body: otp,
        }
      },
      invalidatesTags: ["CurrentVendor"],
    }),
    verifyPhoneOtp: builder.mutation<any, { otp: number }>({
      query(otp) {
        return {
          url: "vendor/verify-phone-otp",
          method: "POST",
          body: otp,
        }
      },
      invalidatesTags: ["CurrentVendor"],
    }),
    verifyVendorPayment: builder.mutation<any, { transactionToken: string }>({
      query(transactionToken) {
        return {
          url: "public/verify-payment",
          method: "POST",
          body: transactionToken,
          credentials: "omit",
        }
      },
    }),
    deactivateSubscription: builder.mutation<any, void>({
      query() {
        return {
          url: "vendor/deactivate-subscription",
          method: "POST",
        }
      },
      invalidatesTags: ["CurrentVendor"],
    }),
    activateSubscription: builder.mutation<
      any,
      { transactionToken: string; transactionId: string }
    >({
      query(data) {
        return {
          url: "vendor/activate-subscription",
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["CurrentVendor"],
    }),
    getAllPaymentsAdmin: builder.query<PaymentsAdmin[], void>({
      query() {
        return {
          url: "admin/get-all-payments",
          method: "GET",
        }
      },
    }),
    getAllVendors: builder.query<any[], void>({
      query() {
        return {
          url: "public/vendors",
          method: "GET",
          credentials: "omit",
        }
      },
    }),
    getAllListings: builder.query<any[], void>({
      query(arg) {
        return {
          url: "public/listings",
          method: "GET",
          credentials: "omit",
        }
      },
    }),
    getAllCategoriesAdmin: builder.query<CategoriesAdmin[], void>({
      query() {
        return {
          url: "admin/categories",
          method: "GET",
        }
      },
      providesTags: ["CategoriesAdmin"],
    }),
    addCategoryAdmin: builder.mutation<any, { categoryName: string }>({
      query(categoryData) {
        return {
          url: "admin/create-category",
          method: "POST",
          body: categoryData,
        }
      },
      invalidatesTags: ["CategoriesAdmin", "CategoriesPublic"],
    }),
    searchListings: builder.query<
      any[],
      {
        districtName?: string
        categoryName?: string
        minPrice?: number
        maxPrice?: number
        latitude?: number
        longitude?: number
      }
    >({
      query(searchParams) {
        return {
          url: `public/listings/search`,
          params: searchParams,
          method: "GET",
          credentials: "omit",
        }
      },
    }),
    getAllCategories: builder.query<CategoriesPublic[], void>({
      query() {
        return {
          url: "public/categories",
          method: "GET",
          credentials: "omit",
        }
      },
      providesTags: ["CategoriesPublic"],
    }),
    addListingCustomer: builder.mutation<any, { listingId: number }>({
      query(listingId) {
        return {
          url: "customer/add-listing",
          method: "POST",
          body: listingId,
        }
      },
      invalidatesTags: ["AllCustomerListings"],
    }),
    getAllCustomerListings: builder.query<any[], void>({
      query() {
        return {
          url: "customer/get-listings",
          method: "GET",
        }
      },
      providesTags: ["AllCustomerListings"],
    }),
    getCustomerListingStatus: builder.query<any, { listingId: number }>({
      query(listingId) {
        return {
          url: "customer/get-customer-listing-status",
          method: "GET",
          body: listingId,
        }
      },
      providesTags: ["CustomerListingStatus"],
    }),
    updateCustomerListingStatus: builder.mutation<
      any,
      { listingId: number; status: string }
    >({
      query(data) {
        return {
          url: "customer/update-customer-listing-status",
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["CustomerListingStatus", "AllCustomerListings"],
    }),
    updateCustomerListingNotes: builder.mutation<
      any,
      { listingId: number; notes: string }
    >({
      query(data) {
        return {
          url: "customer/update-customer-listing-notes",
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["CustomerListingStatus"],
    }),
    addRating: builder.mutation<any, Ratings>({
      query(data) {
        return { url: "customer/add-rating", method: "POST", body: data }
      },
      invalidatesTags: ["SingleListing"],
    }),
    addReview: builder.mutation<any, Reviews>({
      query(data) {
        return {
          url: "customer/add-review",
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["SingleListing"],
    }),
    getCustomersAdmin: builder.query<CustomersAdmin[], void>({
      query() {
        return {
          url: "admin/customers",
          method: "GET",
        }
      },
      providesTags: ["CustomersAdmin"],
    }),
    activateCustomer: builder.mutation<any, { customerId: number }>({
      query(customerId) {
        return {
          url: "admin/activate-customer",
          method: "POST",
          body: customerId,
        }
      },
      invalidatesTags: ["CustomersAdmin"],
    }),
    deactivateCustomer: builder.mutation<any, { customerId: number }>({
      query(customerId) {
        return {
          url: "admin/deactivate-customer",
          method: "POST",
          body: customerId,
        }
      },
      invalidatesTags: ["CustomersAdmin"],
    }),
    sendContactFormMessage: builder.mutation<any, Contact>({
      query(data) {
        return {
          url: "public/contact",
          method: "POST",
          body: data,
          credentials: "omit",
        }
      },
    }),
    requestPricing: builder.mutation<any, RequestPricing>({
      query(data) {
        return {
          url: "public/request-pricing",
          method: "POST",
          body: data,
        }
      },
    }),
    newLetter: builder.mutation<any, { email: string }>({
      query(data) {
        return {
          url: "public/newsletter",
          method: "POST",
          body: data,
        }
      },
    }),
    forgotPassword: builder.mutation<any, { email: string; userType: string }>({
      query(data) {
        return {
          url: "/public/forgot-password",
          method: "POST",
          body: data,
        }
      },
    }),
    resetPassword: builder.mutation<
      any,
      {
        password: string
        confirmPassword: string
        token: string
        userType: string
      }
    >({
      query(data) {
        return {
          url: "public/reset-password",
          method: "POST",
          body: data,
        }
      },
    }),
  }),
})

export const {
  useVendorLoginMutation,
  useVendorRegisterMutation,
  useCustomerLoginMutation,
  useAdminLoginMutation,
  useUpdateBusinessProfileMutation,
  useUpdateBrandImageMutation,
  useGetVendorListingsQuery,
  useGetBusinessDataQuery,
  useGetSingleVendorQuery,
  useCreateListingMutation,
  useDeleteListingMutation,
  useCreateSocialMediaLinkMutation,
  useGetSocialMediaLinksQuery,
  useEditSocialMediaLinkMutation,
  useGetVendorReviewsQuery,
  useAddReviewCommentMutation,
  useGetCurrentVendorQuery,
  useGetVendorRatingsQuery,
  useGetAllVendorsAdminQuery,
  useActivateVendorAccountMutation,
  useDeactivateVendorAccountMutation,
  useApproveVendorNationalIdMutation,
  useRejectVendorNationalIdMutation,
  useApproveVendorCertificateOfIncorporationMutation,
  useRejectVendorCertificateOfIncorporationMutation,
  useVendorPaymentMutation,
  useActivateSubscriptionMutation,
  useDeactivateSubscriptionMutation,
  useVerifyVendorPaymentMutation,
  useGetAllVendorsQuery,
  useGetAllListingsQuery,
  useSearchListingsQuery,
  useLazySearchListingsQuery,
  useGetSingleListingQuery,
  useUpdateListingMutation,
  useGetListingsAdminQuery,
  useUpdateListingStatusAdminMutation,
  useGetAllPaymentsAdminQuery,
  useGetAllCategoriesAdminQuery,
  useAddCategoryAdminMutation,
  useGetAllCategoriesQuery,
  useAddListingCustomerMutation,
  useGetAllCustomerListingsQuery,
  useGetCustomerListingStatusQuery,
  useUpdateCustomerListingStatusMutation,
  useUpdateCustomerListingNotesMutation,
  useUpdateCustomerProfileMutation,
  useGetCurrentCustomerQuery,
  useCustomerRegisterMutation,
  useAddRatingMutation,
  useGetCustomersAdminQuery,
  useActivateCustomerMutation,
  useDeactivateCustomerMutation,
  useAddReviewMutation,
  useSendContactFormMessageMutation,
  useSendPhoneOTpMutation,
  useSendEmailOTpMutation,
  useVerifyEmailOtpMutation,
  useVerifyPhoneOtpMutation,
  useGetCurrentAdminQuery,
  useUpdateAdminProfileMutation,
  useRequestPricingMutation,
  useNewLetterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = api
