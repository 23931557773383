import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type User = string[]

export interface authState {
  isAuthenticated: boolean
  user: User
  loginPageUser: string
}

const initialState: authState = {
  isAuthenticated: false,
  user: ["ROLE_USER"] as unknown as User,
  loginPageUser: "",
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.isAuthenticated = true
    },
    logout: (state) => {
      state.user = ["ROLE_USER"] as unknown as User
      state.isAuthenticated = false
    },
    setLoginPageUser: (state, action: PayloadAction<string>) => {
      state.loginPageUser = action.payload
    },
  },
})

export const { setUser, logout, setLoginPageUser } = authSlice.actions
export default authSlice.reducer
