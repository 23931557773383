import React, { Suspense, useEffect } from "react"
import DashboardLayout from "./DashboardLayout"
import { Route, Routes } from "react-router-dom"
import ProtectedRoutes from "../../services/auth/ProtectedRoutes"
import { AdminComponents } from "../../features/admin"
import { useAppDispatch } from "../../app/hooks"
import { setAdmin } from "../../features/admin/adminSlice"
import { useGetCurrentAdminQuery } from "../../services/auth/api"
import Spinner from "../reusables/Spinner"

function AdminDashboard() {
  const { data: currentAdmin } = useGetCurrentAdminQuery()
  const dispatch = useAppDispatch()
  useEffect(() => {
    currentAdmin && dispatch(setAdmin(currentAdmin))
  }, [currentAdmin, dispatch])
  return (
    <DashboardLayout>
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyDashboard />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="vendors"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyVendors />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="listings"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyListings />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="customers"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyCustomers />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="payments"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyPayments />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="categories"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyCategories />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <AdminComponents.LazyProfile />
                </Suspense>
              }
              roles={["ROLE_ADMIN"]}
            />
          }
        />
      </Routes>
    </DashboardLayout>
  )
}

export default AdminDashboard
