import React, { Suspense } from "react"
import { Navigate } from "react-router-dom"
import { useReadLocalStorage } from "usehooks-ts"
import { useAppSelector } from "../../app/hooks"
import { VendorComponents } from "../../features/vendor"
import { CustomerComponents } from "../../features/customer"
import Spinner from "../../layouts/reusables/Spinner"

interface ProtectedRoutesProps {
  element: React.ReactNode
  roles: string[]
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({
  element,
  roles,
}) => {
  const role = useReadLocalStorage("role")
  const isAuthenticated = useReadLocalStorage("isAuthenticated")
  const currentVendorIsActiveState = useAppSelector(
    (state) => state.vendor.isActive,
  )

  const currentCustomerIsActiveState = useAppSelector(
    (state) => state.customer.isActive,
  )

  if (isAuthenticated === "false") {
    return <Navigate to={"/login"} />
  }

  if (
    isAuthenticated === "true" &&
    role === "ROLE_VENDOR" &&
    currentVendorIsActiveState === false
  ) {
    return (
      <>
        <Navigate to={"/vendor/profile"} />
        <Suspense
          fallback={
            <>
              <Spinner className="text-theme_secondary" />
            </>
          }
        >
          <VendorComponents.LazyProfile />
        </Suspense>
      </>
    )
  }

  if (
    isAuthenticated === "true" &&
    role === "ROLE_CUSTOMER" &&
    currentCustomerIsActiveState === false
  ) {
    return (
      <>
        <Navigate to={"/customer/profile"} />
        <Suspense
          fallback={
            <>
              <Spinner className="text-theme_secondary" />
            </>
          }
        >
          <CustomerComponents.LazyProfile />
        </Suspense>
      </>
    )
  }
  const hasAccess = roles.includes(role as string)

  if (!hasAccess) {
    return <Navigate to={"/login"} />
  }

  return element
}

export default ProtectedRoutes
