import React, { Fragment, useEffect, useState } from "react"
import { Combobox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline"
import { useSearchPlacesQuery } from "../../../services/places/api"
import Spinner from "../Spinner"

interface SearchPlacesProps {
  placeholder?: string
  onChange?: (lat: number, lon: number) => void
  className?: string
}

const SearchPlaces: React.FC<SearchPlacesProps> = ({
  placeholder,
  onChange,
  className,
}) => {
  const [query, setQuery] = useState("")
  const [selectedPlace, setSelectedPlace] = useState<{
    lat: number
    lon: number
    displayName: string
  } | null>(null)
  const {
    data: results,
    error,
    isLoading,
    isFetching,
  } = useSearchPlacesQuery(query)

  useEffect(() => {
    if (onChange && selectedPlace) {
      onChange(selectedPlace.lat, selectedPlace.lon)
    }
  }, [query, selectedPlace, onChange])

  const handleInputChange = (event: any) => {
    setQuery(event.target.value)
  }

  const handlePlaceSelection = (place: {
    lat: number
    lon: number
    displayName: string
  }) => {
    setSelectedPlace(place)
  }

  return (
    <div className="w-full relative">
      <Combobox
        value={selectedPlace ? selectedPlace.displayName : query}
        onChange={setQuery}
      >
        <div className="relative">
          <div className="relative w-full border cursor-default overflow-hidden bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 text-xs">
            <Combobox.Input
              className={`${className} w-full border-none py-2 text-xs text-gray-900 focus:ring-0`}
              onChange={handleInputChange}
              placeholder={placeholder ?? "Search places..."}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
              {isLoading || isFetching ? (
                <div className="relative cursor-default select-none px-1 py-2 text-gray-700">
                  <Spinner className="text-theme_secondary" />
                </div>
              ) : (
                results &&
                results.length === 0 &&
                query !== "" && (
                  <div className="relative cursor-default select-none px-1 py-2 text-gray-700">
                    Nothing found.
                  </div>
                )
              )}
              {results &&
                results.map((result: any, index: number) => (
                  <Combobox.Option
                    key={index}
                    value={result.display_name}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-4 pr-4 ${
                        active
                          ? "bg-theme_secondary text-white"
                          : "text-gray-900"
                      }`
                    }
                    onClick={() =>
                      handlePlaceSelection({
                        lat: result.lat,
                        lon: result.lon,
                        displayName: result.display_name,
                      })
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {result.display_name}
                        </span>
                        {selected && (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center ${
                              active
                                ? "text-white"
                                : "text-thembg-theme_secondary"
                            }`}
                          >
                            <CheckIcon className="h-3 w-3" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default SearchPlaces
