import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface AdminState {
  id: number
  name: string
  email: string
  phone: string
  profileImage: string
}

const initialState: AdminState = {
  id: 0,
  name: "",
  email: "",
  phone: "",
  profileImage: "",
}

const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<AdminState>) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.email = action.payload.email
      state.phone = action.payload.phone
      state.profileImage = action.payload.profileImage
    },
  },
})

export const { setAdmin } = adminSlice.actions
export default adminSlice.reducer
