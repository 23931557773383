import Map from "../../../layouts/reusables/map/Map"
import { districts, ratingSelectDropDown } from "../../../utils/data"
import Slider from "../../../layouts/reusables/Slider"
import PaginatedItems from "../../../layouts/reusables/PaginatedItems"
import CustomIcon from "../../../layouts/reusables/icons/CustomIcon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import {
  useGetAllCategoriesQuery,
  useGetAllListingsQuery,
  useGetAllVendorsQuery,
  useLazySearchListingsQuery,
} from "../../../services/auth/api"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useLocation, useNavigate } from "react-router-dom"
import CustomSelect from "../../../layouts/reusables/CustomSelect"
import { FormProvider, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { setSearchResults } from "./searchSlice"
import { matchSorter } from "match-sorter"
import SearchPlaces from "../../../layouts/reusables/map/SearchPlaces"

function ListingWithMap({ dataType }: { dataType?: "vendors" | "listings" }) {
  const { data: vendors, refetch: refetchVendors } = useGetAllVendorsQuery()
  const { data: listings, refetch: refetchListings } = useGetAllListingsQuery()
  const { data: categories = [] } = useGetAllCategoriesQuery()
  const searchResults = useAppSelector((state) => state.searchResults.listings)
  const [listingsData, setlistingsData] = useState<any[]>()
  const { width: screenWidth } = useWindowSize()
  const { state } = useLocation()
  const isFromSearch = state && state.fromSearch
  useEffect(() => {
    if (dataType === "vendors") {
      refetchVendors()
    } else if (dataType === "listings") {
      refetchListings()
      if (isFromSearch) {
        const publishedSearchResults = matchSorter(searchResults, "PUBLISH", {
          keys: ["listingStatus"],
        })
        setlistingsData(publishedSearchResults)
      } else if (!isFromSearch) {
        const publishedListings = listings
          ? matchSorter(listings, "PUBLISH", {
              keys: ["listingStatus"],
            })
          : []
        setlistingsData(publishedListings)
      }
    }
  }, [
    dataType,
    refetchVendors,
    refetchListings,
    isFromSearch,
    searchResults,
    listings,
  ])
  const [categoryName, setCategoryName] = useState<string | undefined>()
  const [districtName, setDistrictName] = useState<string | undefined>()
  const [minPrice, setMinPrice] = useState<number | undefined>()
  const [maxPrice, setMaxPrice] = useState<number | undefined>()
  const [latitude, setLatitude] = useState<number | undefined>()
  const [longitude, setLongitude] = useState<number | undefined>()
  const [trigger] = useLazySearchListingsQuery()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleSliderInputChange = ({
    minPrice,
    maxPrice,
  }: {
    minPrice: number
    maxPrice: number
  }) => {
    setMinPrice(minPrice)
    setMaxPrice(maxPrice)
  }
  const handleCategoryClick = (
    route: any,
    dataType: "vendors" | "listings",
    state: any = null,
  ) => {
    navigate(route, { state: { dataType, ...state } })
  }
  const handleClick = async ({
    categoryName,
    minPrice,
    maxPrice,
    latitude,
    longitude,
  }: {
    categoryName?: string
    districtName?: string
    minPrice?: number
    maxPrice?: number
    latitude?: number
    longitude?: number
  }) => {
    if (categoryName) {
      await trigger({ categoryName: categoryName })
        .unwrap()
        .then((response: any[]) => {
          dispatch(setSearchResults({ listings: response }))
          handleCategoryClick("/listing-with-map", "listings", {
            fromSearch: true,
          })
        })
        .catch((error: any) => {
          toast.warn(error.data.error)
        })
    }
    if (latitude && longitude) {
      await trigger({ latitude: latitude, longitude: longitude })
        .unwrap()
        .then((response: any[]) => {
          dispatch(setSearchResults({ listings: response }))
          handleCategoryClick("/listing-with-map", "listings", {
            fromSearch: true,
          })
        })
        .catch((error: any) => {
          toast.warn(error.data.error)
        })
    }
    if (minPrice && maxPrice) {
      await trigger({ minPrice: minPrice, maxPrice: maxPrice })
        .unwrap()
        .then((response: any[]) => {
          dispatch(setSearchResults({ listings: response }))
          handleCategoryClick("/listing-with-map", "listings", {
            fromSearch: true,
          })
        })
        .catch((error: any) => {
          toast.warn(error.data.error)
        })
    }
  }
  const dataToUse = dataType === "vendors" ? vendors : listingsData
  const methods = useForm()
  return (
    <FormProvider {...methods}>
      <div className="grid grid-cols-1 xl:grid-cols-5 w-full">
        <div className="xl:col-span-3">
          <div className="bg-gray-200 grid-rows-3 px-6 pb-20 pt-6">
            <div className="grid md:grid-cols-3 items-center row-span-1 space-y-2 md:space-y-0 md:gap-x-2">
              <CustomSelect
                options={["", ...(categories as any)]}
                placeholder={
                  screenWidth <= 1024 ? "Category" : "Select Category"
                }
                name="categories"
                className="w-full text-xs text-gray-400 bg-gray-100 border-gray-300 rounded rounded-r-none focus:border-gray-300 focus:ring-0 focus:outline-none"
                onChange={(event: string) => setCategoryName(event)}
              />
              {/* <CustomSelect
                options={["", ...districts]}
                placeholder={
                  screenWidth <= 1024 ? "Location" : "Choose Vendor Location"
                }
                name="location"
                className="w-full text-xs text-gray-400 bg-gray-100 border-gray-300 rounded-none focus:border-gray-300 focus:ring-0 focus:outline-none"
                onChange={(event: string) => setDistrictName(event)}
              /> */}
              <SearchPlaces
                placeholder={
                  screenWidth <= 1024 ? "Location" : "Choose Vendor Location"
                }
                onChange={(lat, lng) => {
                  setLatitude(lat)
                  setLongitude(lng)
                }}
              />
              <Slider
                className="border-none shadow-none"
                onInputChange={handleSliderInputChange}
              />
            </div>
            <button
              onClick={() =>
                handleClick({
                  categoryName: categoryName,
                  latitude: latitude,
                  longitude: longitude,
                  minPrice: minPrice,
                  maxPrice: maxPrice,
                })
              }
              className="row-span-2 mt-10 text-white bg-theme_secondary hover:bg-theme_primary font-theme_secondary_light text-sm px-3 py-2 rounded"
            >
              Search
            </button>
          </div>
          <div className="bg-white py-6">
            <div className="grid grid-cols-4 items-center px-6 my-4">
              <div className="col-span-4 md:col-span-3 w-full space-x-2">
                <CustomIcon
                  type={"faBorderAll" as unknown as IconDefinition}
                  className="text-theme_secondary hover:text-theme_primary bg-white rounded-full border-theme_secondary hover:border-theme_primary border-2 cursor-pointer p-2"
                />
                <CustomIcon
                  type={"faList" as unknown as IconDefinition}
                  className="text-theme_secondary hover:text-theme_primary bg-white rounded-full border-theme_secondary hover:border-theme_primary border-2 cursor-pointer p-2"
                />
              </div>
              <div className="col-span-4 md:col-span-1 w-full">
                <CustomSelect
                  options={ratingSelectDropDown}
                  placeholder={"Top Rated"}
                  name="rating"
                  className="text-xs text-gray-400 bg-gray-100 border-gray-300 rounded-none focus:border-gray-300 focus:ring-0 focus:outline-none"
                  onChange={(event: string) => console.log(event)}
                />
              </div>
            </div>
          </div>
          <PaginatedItems
            listings={listingsData ? listingsData : []}
            itemsPerPage={3}
          />
        </div>
        <div className="w-full xl:col-span-2">
          <Map
            key={screenWidth}
            className="z-0"
            height={
              screenWidth === 2560
                ? screenWidth - 1500
                : screenWidth > 1366
                ? screenWidth - 500
                : screenWidth > 1024
                ? screenWidth - 500
                : 500
            }
            dataType={dataType}
            // vendors={dataToUse}
            listings={dataToUse}
          />
        </div>
      </div>
    </FormProvider>
  )
}

export default ListingWithMap
