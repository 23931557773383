import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import CustomIcon from "../../reusables/icons/CustomIcon"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setDashboard, setIsOpen } from "../sideBarsSlice"
import useReadLocalStorage from "usehooks-ts/dist/esm/useReadLocalStorage/useReadLocalStorage"
import { useAuth } from "../../../services/auth/AuthContext"
import { useLocalStorage } from "usehooks-ts"
import { setLoginPageUser } from "../../../services/auth/authSlice"
import {
  useActivateSubscriptionMutation,
  useDeactivateSubscriptionMutation,
  useVendorPaymentMutation,
  useVerifyVendorPaymentMutation,
} from "../../../services/auth/api"
import { toast } from "react-toastify"
import Spinner from "../../reusables/Spinner"

function SideBarDashboard() {
  const currentVendor = useAppSelector((state) => state.vendor)
  const currentCustomer = useAppSelector((state) => state.customer)
  const currentAdmin = useAppSelector((state) => state.admin)
  const [transactionToken, setTransactionToken] = useState("")
  const [transactionId, setTransactionId] = useState<string>("")
  const [vendorPayment, { isLoading: isLoadingPayment }] =
    useVendorPaymentMutation()
  const [verifyVendorPayment, { isLoading: isLoadingVerifyVendorPayment }] =
    useVerifyVendorPaymentMutation()
  const [activateSubscription, { isLoading: isLoadingActivateSubsccription }] =
    useActivateSubscriptionMutation()
  const [
    deactivateSubscription,
    { isLoading: isLoadingDeactivateSubscription },
  ] = useDeactivateSubscriptionMutation()

  const handlePayment = async () => {
    await vendorPayment()
      .unwrap()
      .then((response) => {
        toast.info("Please hold on as we redirect you to the payment page")
        window.open(response.paymentUrl, "_self")
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    const handleDeactivateSubscription = async () => {
      if (
        currentVendor.daysUntilExpiration !== null &&
        currentVendor.daysUntilExpiration <= 0 &&
        currentVendor.isActive === true
      ) {
        await deactivateSubscription()
          .unwrap()
          .then((response) => toast.success(response))
      }
    }
    handleDeactivateSubscription()
  }, [
    currentVendor.daysUntilExpiration,
    currentVendor.isActive,
    deactivateSubscription,
  ])

  const [, setRole] = useLocalStorage("role", "ROLE_USER")
  const [, setIsAuthenticated] = useLocalStorage("isAuthenticated", "false")
  const [currentUrl, setcurrentUrl] = useState("")
  const dispatch = useAppDispatch()
  const pathName = useLocation().pathname
  useEffect(() => {
    dispatch(setDashboard(true))
    setcurrentUrl(pathName)
  }, [dispatch, pathName])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const transactionTokenURL = urlSearchParams.get("TransactionToken")
    const transactionIdURL = urlSearchParams.get("TransID")
    const verifyPayment = async () => {
      if (transactionTokenURL && transactionIdURL && transactionTokenURL) {
        const response = await verifyVendorPayment({
          transactionToken: transactionTokenURL ?? "",
        }).unwrap()
        if (response.paymentVerification === "000") {
          await activateSubscription({
            transactionId: transactionIdURL,
            transactionToken: transactionTokenURL,
          })
            .unwrap()
            .then(() => toast.success("Subscription activated successfully"))
            .catch((error) => toast.error(error.data))
        } else {
          toast.error(
            "Please complete your payment to activate your subscription",
          )
        }
      }
    }
    if (transactionIdURL) {
      setTransactionId(transactionIdURL)
    }

    if (transactionTokenURL && transactionTokenURL !== "") {
      setTransactionToken(transactionTokenURL)
      verifyPayment()
    }
  }, [
    activateSubscription,
    transactionId,
    transactionToken,
    verifyVendorPayment,
  ])

  const logoutAuthContext = useAuth().logoutFunction
  const handleLogout = () => {
    logoutAuthContext()
    setIsAuthenticated("false")
    setRole("ROLE_USER")
    dispatch(setLoginPageUser(""))
  }
  const role = useReadLocalStorage("role")
  return (
    <div className={"w-full lg:w-60 space-y-4 flex flex-col items-center"}>
      {isLoadingDeactivateSubscription ||
        (isLoadingPayment && <Spinner className="text-theme_secondary" />)}
      <div className="space-y-2">
        <div className="w-28 mx-auto">
          <img
            src={
              role === "ROLE_CUSTOMER"
                ? `${
                    currentCustomer.profileImage ??
                    "/images/placeholder-light.png"
                  }`
                : role === "ROLE_VENDOR"
                ? `${
                    currentVendor.profileImage ??
                    "/images/placeholder-light.png"
                  }`
                : `${
                    currentAdmin.profileImage ?? "/images/placeholder-light.png"
                  }`
            }
            alt="profile"
            className="rounded-[100%] w-28 h-28 object-cover object-center"
          />
        </div>
        <div
          className={
            (role === "ROLE_CUSTOMER"
              ? "text-theme_secondary"
              : role === "ROLE_VENDOR"
              ? "text-theme_primary"
              : "") + " font-theme_secondary_bold capitalize flex flex-col"
          }
        >
          {role === "ROLE_CUSTOMER"
            ? `${currentCustomer ? currentCustomer.name : "Customer Name"}`
            : role === "ROLE_VENDOR"
            ? `Welcome ${currentVendor.fullName}`
            : role === "ROLE_ADMIN"
            ? `Welcome ${currentAdmin.name}`
            : "Welcome unknown"}
          {role === "ROLE_VENDOR" &&
            (currentVendor.daysUntilExpiration &&
            currentVendor.daysUntilExpiration > 0 ? (
              <span className="text-red-500 text-xs">
                Subscription Status: {currentVendor.daysUntilExpiration} days
              </span>
            ) : (
              <button
                onClick={handlePayment}
                className="rounded text-white bg-theme_primary hover:bg-theme_secondary font-theme_secondary_bold text-xs px-1 py-1"
              >
                Make Payment
              </button>
            ))}
        </div>
      </div>
      <div className="lg:shadow-lg bg-white p-8 rounded-md font-theme_secondary_bold flex flex-col space-y-4">
        <div>
          <Link
            to={
              role === "ROLE_CUSTOMER"
                ? "/customer"
                : role === "ROLE_VENDOR"
                ? "/vendor"
                : role === "ROLE_ADMIN"
                ? "/admin"
                : "/customer"
            }
            className={
              (currentUrl === "/customer" ||
              currentUrl === "/vendor" ||
              currentUrl === "/admin"
                ? "text-theme_secondary"
                : "") +
              ` flex flex-row space-x-3 hover:text-theme_secondary items-center`
            }
            onClick={() => dispatch(setIsOpen(false))}
          >
            <div>
              <CustomIcon
                type={"faGauge" as unknown as IconDefinition}
                className="text-theme_secondary text-lg"
              />
            </div>
            <div className="text-sm">Dashboard</div>
          </Link>
        </div>
        {role === "ROLE_ADMIN" && (
          <div>
            <Link
              to={"/admin/vendors"}
              className={
                (currentUrl === "/admin/vendors"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faUsers" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Vendors</div>
            </Link>
          </div>
        )}
        {role === "ROLE_ADMIN" && (
          <div>
            <Link
              to={"/admin/listings"}
              className={
                (currentUrl === "/admin/listings"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faList" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Listings</div>
            </Link>
          </div>
        )}
        {role === "ROLE_ADMIN" && (
          <div>
            <Link
              to={"/admin/customers"}
              className={
                (currentUrl === "/admin/customers"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faUsers" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Customers</div>
            </Link>
          </div>
        )}
        {role === "ROLE_ADMIN" && (
          <div>
            <Link
              to={"/admin/payments"}
              className={
                (currentUrl === "/admin/payments"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faMoneyBill" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Payments</div>
            </Link>
          </div>
        )}
        {role === "ROLE_ADMIN" && (
          <div>
            <Link
              to={"/admin/categories"}
              className={
                (currentUrl === "/admin/categories"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faLayerGroup" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Categories</div>
            </Link>
          </div>
        )}
        {role === "ROLE_CUSTOMER" && (
          <div>
            <Link
              to={"/customer/vendor-manager"}
              className={
                (currentUrl === "/customer/vendor-manager"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faBook" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Vendor Manager</div>
            </Link>
          </div>
        )}
        {role === "ROLE_VENDOR" && (
          <div>
            <Link
              to={"/vendor/listings"}
              className={
                (currentUrl === "/vendor/listings"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faRectangleList" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">My Listings</div>
            </Link>
          </div>
        )}
        {role === "ROLE_VENDOR" && (
          <div>
            <Link
              to={"/vendor/reviews"}
              className={
                (currentUrl === "/vendor/reviews"
                  ? "text-theme_secondary"
                  : "") +
                " flex flex-row space-x-3 hover:text-theme_secondary items-center"
              }
              onClick={() => dispatch(setIsOpen(false))}
            >
              <div>
                <CustomIcon
                  type={"faStar" as unknown as IconDefinition}
                  className="text-theme_secondary text-lg"
                />
              </div>
              <div className="text-sm">Reviews</div>
            </Link>
          </div>
        )}
        <div>
          <Link
            to={
              role === "ROLE_CUSTOMER"
                ? "/customer/profile"
                : role === "ROLE_VENDOR"
                ? "/vendor/profile"
                : role === "ROLE_ADMIN"
                ? "/admin/profile"
                : "/customer/profile"
            }
            className={
              (currentUrl === "/customer/profile" ||
              currentUrl === "/vendor/profile"
                ? "text-theme_secondary"
                : "") +
              " flex flex-row space-x-3 hover:text-theme_secondary items-center"
            }
            onClick={() => dispatch(setIsOpen(false))}
          >
            <div>
              <CustomIcon
                type={"faUser" as unknown as IconDefinition}
                className="text-theme_secondary text-lg"
              />
            </div>
            <div className="text-sm">My Profile</div>
          </Link>
        </div>
        <div>
          <div
            onClick={handleLogout}
            className="flex flex-row space-x-3 text-red-600 hover:text-theme_primary cursor-pointer"
          >
            <div>
              <CustomIcon
                type={"faPowerOff" as unknown as IconDefinition}
                className="text-lg"
              />
            </div>
            <div>Logout</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBarDashboard
