import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import {
  ListingsData,
  TopEventsLisitingsCardProps,
  TopEventsVendorsCardProps,
} from "./types"

export const topEventsVendors: TopEventsVendorsCardProps[] = [
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/qim5h0mdpuofh7hu0dn7.jpg",
    vendorCategory: "Photography",
    vendorLocation: "Wakiso",
    iconName: "faCamera" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/opwr9sohwspxhdtkh3jj.jpg",
    vendorCategory: "Decoration",
    vendorLocation: "Kampala",
    iconName: "faWandSparkles" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/a0ckevhd6jg9zyohrdne.jpg",
    vendorCategory: "Venues",
    vendorLocation: "Kampala",
    iconName: "faPlaceOfWorship" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/rnelrapwbacysqidkywd.jpg",
    vendorCategory: "Jewellers",
    vendorLocation: "Kabale",
    iconName: "faGem" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/qr6yzwq3kt9rka4ptaq9.jpg",
    vendorCategory: "Car Hire Services",
    vendorLocation: "Kampala",
    iconName: "faCar" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/n3zys8clvsxfoxyxpsxa.png",
    vendorCategory: "Cakes",
    vendorLocation: "Tororo",
    iconName: "faCakeCandles" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/glmul6ja76yk8xk9zm8n.png",
    vendorCategory: "Wedding Gowns",
    vendorLocation: "Kabale",
    iconName: "faCrown" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/rescca6jxtz7g16jvwh5.png",
    vendorCategory: "Music Systems",
    vendorLocation: "Kampala",
    iconName: "faRecordVinyl" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/smss2fcr2kzlz45xsnp5.jpg",
    vendorCategory: "Catering Services",
    vendorLocation: "Kampala",
    iconName: "faUtensils" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/z0icos9tjdpq12oe7dkd.jpg",
    vendorCategory: "Bridal Salons",
    vendorLocation: "Kampala",
    iconName: "faPersonDress" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/q6ktzxfetc2efqhk74ws.jpg",
    vendorCategory: "Suits",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/v1egixv8pxo9c9b5lwvy.jpg",
    vendorCategory: "Make-up Services",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/drbiorneev4sgqqkrhdv.jpg",
    vendorCategory: "MC",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/yiueelqjkzmomaax9mli.jpg",
    vendorCategory: "Videography",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/dj3q3qr1dpe286zzavel.jpg",
    vendorCategory: "Cultural Wear",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/mbllihegvykr2txl0nki.jpg",
    vendorCategory: "Spas",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/cgwpzint1efaq8pz0hym.jpg",
    vendorCategory: "Manicure and Pedicure",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/a0ckevhd6jg9zyohrdne.jpg",
    vendorCategory: "Honeymoon spots",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/poul2kkvkzo3tuz9ztuw.jpg",
    vendorCategory: "Ushering Services",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/e4xpql8qmsbu2n9sasdy.jpg",
    vendorCategory: "Lingerie wear",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/gozfj9iqx5elmqlwqu8v.jpg",
    vendorCategory: "Counselling services",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/llxuwc2vtozur87fhjpw.jpg",
    vendorCategory: "Footwear (Male and Female)",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/hfg7uk1ptoyejp6olchx.jpg",
    vendorCategory: "Gift packaging services",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/u4cy9drsweuc1wwtlufv.jpg",
    vendorCategory: "Meeting venues",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/epdlfhhhkrfsqxq1lmpx.jpg",
    vendorCategory: "Cleaning Up services",
    vendorLocation: "Kampala",
    iconName: "faUserTie" as unknown as IconDefinition,
  },
]

export const topEventsListings: TopEventsLisitingsCardProps[] = [
  {
    listingId: 1,
    imageUrl: "/images/home/events-listing/items/fashion.jpg",
    vendorCategory: "Fashion",
    vendorLocation: "Wakiso",
    iconName: "faCamera" as unknown as IconDefinition,
    vendorName: "Happy Events Fashions",
    reviews: 16,
  },
  {
    listingId: 2,
    imageUrl: "/images/home/events-listing/items/photography.jpg",
    vendorCategory: "Photography",
    vendorLocation: "Kampala",
    iconName: "faCamera" as unknown as IconDefinition,
    vendorName: "Cool Photography",
    reviews: 22,
  },
  {
    listingId: 3,
    imageUrl: "/images/home/events-listing/items/decoration.jpg",
    vendorCategory: "Decoration",
    vendorLocation: "Kampala",
    iconName: "faPlaceOfWorship" as unknown as IconDefinition,
    vendorName: "Lotus Florist",
    reviews: 18,
  },
  {
    listingId: 4,
    imageUrl: "/images/home/events-listing/items/suits.jpg",
    vendorCategory: "Suits",
    vendorLocation: "Kabale",
    iconName: "faGem" as unknown as IconDefinition,
    vendorName: "Emerald Suits",
    reviews: 16,
  },
  {
    listingId: 5,
    imageUrl: "/images/home/events-listing/items/cars.jpg",
    vendorCategory: "Car Hire Services",
    vendorLocation: "Kampala",
    iconName: "faCar" as unknown as IconDefinition,
    vendorName: "V&M Cars",
    reviews: 22,
  },
]

export const vendorCategoriesSelectData = [
  { value: "wedding-gowns", label: "Wedding Gowns" },
  { value: "cakes", label: "Cakes" },
  { value: "venues", label: "Venues" },
  { value: "music-systems", label: "Music Systems" },
  { value: "decoration", label: "Decoration" },
  { value: "jewellers", label: "Jewellers" },
  { value: "car-hire-services", label: "Car Hire Services" },
  { value: "catering-services", label: "Catering Services" },
  { value: "bridal-salons", label: "Bridal Salons" },
  { value: "suits", label: "Suits" },
  { value: "photography", label: "Photography" },
  { value: "make-up-services", label: "Make-Up Services" },
]

export const ratingSelectDropDown = ["Top Rated"]

export const selectBoxCategories = [
  { value: "photography", label: "Photography" },
  { value: "decoration", label: "Decoration" },
]

export const selectDistrictCategories = [
  { value: "kampala", label: "Kampala" },
  { value: "kabale", label: "Kabale" },
  { value: "jinja", label: "Jinja" },
  { value: "wakiso", label: "Wakiso" },
  { value: "tororo", label: "Tororo" },
  { value: "fort portal", label: "Fort Portal" },
]

export const categoryOptions = [
  "Category",
  "Cakes",
  "Photography",
  "Wedding Gowns",
]

export const districtOptions = [
  "District",
  "Kampala",
  "Kabale",
  "Jinja",
  "Wakiso",
  "Tororo",
  "Fort Portal",
]

export const customSelectOptions = [
  "Category",
  "Cakes",
  "Photography",
  "Wedding Gowns",
]

export const listingsData: ListingsData[] = [
  {
    imageUrl: "/images/vendor/listings/listing-1.jpg",
    title: "Muliika Events Photography",
    location: "Kampala",
    dateAdded: "January 18, 2023",
    status: "Pending",
  },
  {
    imageUrl: "/images/vendor/listings/listing-2.jpg",
    title: "KML Car Hire Services",
    location: "Fort Portal",
    dateAdded: "March 20, 2023",
    status: "Publish",
  },
  {
    imageUrl: "/images/vendor/listings/listing-3.jpg",
    title: "Kavumba Recreation",
    location: "Wakiso",
    dateAdded: "April 15, 2023",
    status: "Draft",
  },
  {
    imageUrl: "/images/vendor/listings/listing-4.jpg",
    title: "Eternal Spark Jewels",
    location: "Kabale",
    dateAdded: "May 27, 2023",
    status: "Awaiting Approval",
  },
  {
    imageUrl: "/images/vendor/listings/listing-5.jpg",
    title: "Sugar Bliss Confectionary",
    location: "Wakiso",
    dateAdded: "June 13, 2023",
    status: "Removed",
  },
]

export const clientVendorManagerVendorsData = [
  {
    imageUrl: "/images/customer/dashboard/vendor-manager/event-1.jpg",
    vendorName: "Events MC",
    vendorLocation: "Kampala",
  },
  {
    imageUrl: "/images/customer/dashboard/vendor-manager/event-2.jpg",
    vendorName: "Gina's Bakery",
    vendorLocation: "Wakiso",
  },
]

export const sortingByData = [
  { value: "highest", label: "Rating: Highest" },
  { value: "lowest", label: "Rating: Lowest" },
]

export const categoriesMenu = [
  {
    icon: "faHouse",
    title: "Wedding Gowns",
  },
  {
    icon: "faCakeCandles",
    title: "Cakes",
  },
  {
    icon: "faHouse",
    title: "Venues",
  },
  {
    icon: "faMusic",
    title: "Music Systems",
  },
  {
    icon: "faGem",
    title: "Decoration",
  },
  {
    icon: "faGem",
    title: "Jewellers",
  },
  {
    icon: "faCar",
    title: "Car Hire Services",
  },
  {
    icon: "faUser",
    title: "Catering Services",
  },
  {
    icon: "faUser",
    title: "Bridal Salons",
  },
  {
    icon: "faUserTie",
    title: "Suits",
  },
  {
    icon: "faCameraRetro",
    title: "Photography",
  },
  {
    icon: "faUser",
    title: "Make-Up Services",
  },
]

export const popularCategories = [
  {
    title: "Photography",
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/qim5h0mdpuofh7hu0dn7.jpg",
  },
  {
    title: "Music Systems",
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/events-vendors/rescca6jxtz7g16jvwh5.png",
  },
  {
    title: "Cakes",
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/popular-categories/cakes_gnquki.jpg",
  },
  {
    title: "Venues",
    imageUrl:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/popular-categories/venues_r60uqz.jpg",
  },
]

export const categories = ["Cakes", "Photography", "Car Hire Services"]

export const footerCategories = [
  { title: "Venues" },
  { title: "Suits" },
  { title: "Decoration" },
  { title: "Cakes" },
  { title: "Photography" },
  { title: "Music Systems" },
]

export const footerDistricts = [
  "Kampala",
  "Jinja",
  "Fort Portal",
  "Tororo",
  "Wakiso",
  "Kabale",
]

export const districts = [
  "Abim",
  "Adjumani",
  "Agago",
  "Alebtong",
  "Amolatar",
  "Amudat",
  "Amuria",
  "Amuru",
  "Apac",
  "Arua",
  "Budaka",
  "Bududa",
  "Bugiri",
  "Bugweri",
  "Buhweju",
  "Buikwe",
  "Bukedea",
  "Bukomansimbi",
  "Bukwa",
  "Bulambuli",
  "Buliisa",
  "Bundibugyo",
  "Bunyangabu",
  "Bushenyi",
  "Busia",
  "Butaleja",
  "Butambala",
  "Butebo",
  "Buvuma",
  "Buyende",
  "Dokolo",
  "Gomba",
  "Gulu",
  "Hoima",
  "Ibanda",
  "Iganga",
  "Isingiro",
  "Jinja",
  "Kaabong",
  "Kabale",
  "Kabarole",
  "Kaberamaido",
  "Kagadi",
  "Kakumiro",
  "Kalangala",
  "Kaliro",
  "Kalungu",
  "Kampala",
  "Kamuli",
  "Kamwenge",
  "Kanungu",
  "Kapchorwa",
  "Kapelebyong",
  "Kasanda",
  "Kasese",
  "Katakwi",
  "Kayunga",
  "Kibaale",
  "Kiboga",
  "Kibuku",
  "Kikuube",
  "Kiruhura",
  "Kiryandongo",
  "Kisoro",
  "Kitgum",
  "Koboko",
  "Kole",
  "Kotido",
  "Kumi",
  "Kwania",
  "Kween",
  "Kyankwanzi",
  "Kyegegwa",
  "Kyenjojo",
  "Kyotera",
  "Lamwo",
  "Lira",
  "Luuka",
  "Luwero",
  "Lwengo",
  "Lyantonde",
  "Manafwa",
  "Maracha",
  "Masaka",
  "Masindi",
  "Mayuge",
  "Mbale",
  "Mbarara",
  "Mitooma",
  "Mityana",
  "Moroto",
  "Moyo",
  "Mpigi",
  "Mubende",
  "Mukono",
  "Nabilatuk",
  "Nakapiripirit",
  "Nakaseke",
  "Nakasongola",
  "Namayingo",
  "Namisindwa",
  "Namutumba",
  "Napak",
  "Nebbi",
  "Ngora",
  "Ntoroko",
  "Ntungamo",
  "Nwoya",
  "Omoro",
  "Otuke",
  "Oyam",
  "Pader",
  "Pakwach",
  "Pallisa",
  "Rakai",
  "Rubanda",
  "Rubirizi",
  "Rukiga",
  "Rukungiri",
  "Sembabule",
  "Serere",
  "Sheema",
  "Sironko",
  "Soroti",
  "Tororo",
  "Wakiso",
  "Yumbe",
  "Zombo",
]
