import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface SearchState {
  listings: any[]
}

const initialState: SearchState = {
  listings: [],
}

const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<SearchState>) => {
      state.listings = action.payload.listings
    },
  },
})

export const { setSearchResults } = searchSlice.actions
export default searchSlice.reducer
