import React, { useState } from "react"
import { useNewLetterMutation } from "../../services/auth/api"
import { toast } from "react-toastify"
import Spinner from "../reusables/Spinner"

function NewsLetter() {
  const [email, setEmail] = useState("")
  const [sendNewsLetter, { isLoading }] = useNewLetterMutation()
  const handleSendNewsLetterEmail = async () => {
    await sendNewsLetter({ email: email })
      .unwrap()
      .then((response) => {
        toast.success(response.message)
        setEmail("")
      })
  }
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="flex flex-col justify-start space-y-2"
    >
      {isLoading && <Spinner className="text-theme_secondary" />}
      <input
        placeholder="Enter Email Address"
        value={email}
        className="bg-white text-theme_black text-sm rounded-md p-2 placeholder:text-xs placeholder:text-theme_black w-44 focus:ring-0 focus:outline-0"
        onInput={(e) => setEmail(e.currentTarget.value)}
      />
      <button
        onClick={handleSendNewsLetterEmail}
        className="text-white text-xs font-theme_secondary_bold bg-theme_secondary hover:bg-theme_primary w-24 py-2 rounded-md"
      >
        Subscribe
      </button>
    </form>
  )
}

export default NewsLetter
