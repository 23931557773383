import React, { useEffect, useMemo, useRef, useState } from "react"
import { MapContainer } from "react-leaflet/MapContainer"
import { TileLayer } from "react-leaflet/TileLayer"
import { Marker } from "react-leaflet/Marker"
import { Popup } from "react-leaflet/Popup"
import { useMap } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import { LatLngExpression } from "leaflet"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setPosition } from "./mapSlice"
import L from "leaflet"
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import RatingComponent from "../RatingComponent"
import Spinner from "../Spinner"
import "leaflet-geosearch/dist/geosearch.css"
import { useNavigate } from "react-router-dom"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

function LocationMarker({ defaultPosition }: { defaultPosition?: any }) {
  const markerRef = useRef<any>(null)
  const dispatch = useAppDispatch()
  const mapPositionFromCache = useAppSelector((state) => state.map)
  const [latitude, setLatitude] = useState(
    defaultPosition ? defaultPosition.lat : mapPositionFromCache.LatLng.lat,
  )
  const [longitude, setlongitude] = useState(
    defaultPosition ? defaultPosition.lng : mapPositionFromCache.LatLng.lng,
  )

  const eventHandlers: { dragend: () => void } = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          const { lat, lng } = marker.getLatLng()
          setLatitude(lat)
          setlongitude(lng)
          dispatch(setPosition({ lat: lat, lng: lng }))
        }
      },
    }),
    [dispatch],
  )

  useEffect(() => {
    setLatitude(mapPositionFromCache.LatLng.lat)
    setlongitude(mapPositionFromCache.LatLng.lng)
  }, [mapPositionFromCache.LatLng.lat, mapPositionFromCache.LatLng.lng])
  return (
    <Marker
      position={{ lat: latitude, lng: longitude }}
      draggable
      autoPan
      ref={markerRef}
      eventHandlers={eventHandlers as any}
    />
  )
}

const SearchLocation = (props: any) => {
  const { provider } = props
  const dispatch = useAppDispatch()

  // Get search control
  const searchControl = GeoSearchControl({
    provider: provider,
    style: "bar",
    showMarker: false,
    notFoundMessage: "Sorry, that address could not be found.",
  })

  const map = useMap()

  map.on("geosearch/showlocation", (result: any) => {
    dispatch(setPosition({ lat: result.location.y, lng: result.location.x }))
  })

  //@ts-ignore
  useEffect(() => {
    map.addControl(searchControl)
    return () => map.removeControl(searchControl)
  }, [map])

  return null
}

function Map({
  height,
  className,
  position,
  settable = false,
  popUpComponent,
  popUpComponentData,
  dataType,
  vendors,
  listings,
}: {
  height: number
  className?: string
  position?: LatLngExpression
  settable?: boolean
  popUpComponent?: boolean
  popUpComponentData?: any
  dataType?: "vendors" | "listings"
  vendors?: any[]
  listings?: any[]
}) {
  const [isMapLoaded, setMapLoaded] = useState(false)
  const mapPositionFromCache = useAppSelector((state) => state.map)
  const [zoom, setZoom] = useState(
    dataType === "vendors" || dataType === "listings" ? 10 : 13,
  )
  const navigate = useNavigate()

  useEffect(() => {
    setZoom(dataType === "vendors" || dataType === "listings" ? 10 : 13)
  }, [dataType])

  useEffect(() => {
    // Simulating map loading time
    const mapLoadTimeout = setTimeout(() => {
      setMapLoaded(true)
    }, 500)

    // Cleanup function
    return () => clearTimeout(mapLoadTimeout)
  }, [])

  if (!isMapLoaded) {
    return <Spinner className="text-theme_secondary" /> // Display a loading spinner or any other loading indicator
  }

  const data = dataType === "vendors" ? vendors : listings
  const searchLocationComponent = (
    <SearchLocation provider={new OpenStreetMapProvider()} />
  )
  return (
    <div>
      <MapContainer
        center={
          position
            ? position
            : {
                lat: mapPositionFromCache.LatLng.lat,
                lng: mapPositionFromCache.LatLng.lng,
              }
        }
        zoom={zoom}
        scrollWheelZoom={true}
        style={{ height: `${height}px` }}
        className={className}
      >
        <TileLayer url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
        {settable && searchLocationComponent}
        {data &&
          data.map((item, i) => (
            <Marker
              key={i}
              position={{
                lat: item.gps.latitude ? item.gps.latitude : 0.347596,
                lng: item.gps.longitude ? item.gps.longitude : 32.58252,
              }}
            >
              <Popup>
                {dataType === "vendors" ? (
                  <div className="w-full flex flex-col justify-center rounded-md font-theme_secondary_bold">
                    <div>
                      <img
                        src={item.brandImage ? item.brandImage : ""}
                        alt="vendor-location-popup"
                        className="object-cover object-center aspect-square rounded"
                      />
                    </div>
                    <div>
                      {item.businessName ? item.businessName : "Business Name"}
                    </div>
                    {/* <div>{vendor.vendorCategory}</div> */}
                    {/* <div className="flex flex-row items-center font-theme_secondary_light">
                    <RatingComponent />
                    <div>19</div>
                  </div> */}
                    <button className="capitalize text-white bg-theme_secondary hover:bg-theme_primary rounded text-center px-4 py-1">
                      Request Pricing
                    </button>
                  </div>
                ) : (
                  <div className="w-full flex flex-col justify-center rounded-md font-theme_secondary_bold">
                    <div>
                      <img
                        src={item.featuredImage ? item.featuredImage : ""}
                        alt="listing-location-popup"
                        className="object-cover object-center aspect-square rounded"
                      />
                    </div>
                    <div>
                      {item.listingName ? item.listingName : "Listing Name"}
                    </div>
                    {/* <div>{vendor.vendorCategory}</div> */}
                    {/* <div className="flex flex-row items-center font-theme_secondary_light">
                    <RatingComponent />
                    <div>19</div>
                  </div> */}
                    <button
                      onClick={() =>
                        navigate(`/listing-singular/${item.listingId}`, {
                          state: { listingId: item.listingId },
                        })
                      }
                      className="capitalize text-white bg-theme_secondary hover:bg-theme_primary rounded text-center px-4 py-1"
                    >
                      Request Pricing
                    </button>
                  </div>
                )}
              </Popup>
            </Marker>
          ))}
        {!settable && dataType === undefined ? (
          <Marker position={position ? position : [0.347596, 32.58252]}>
            <Popup>
              {popUpComponent && popUpComponentData ? (
                <div className="w-full flex flex-col justify-center rounded-md font-theme_secondary_bold">
                  <div>
                    <img
                      src={popUpComponentData.imageUrl}
                      alt="vendor-location-popup"
                      className="object-cover object-center aspect-square rounded"
                    />
                  </div>
                  <div>{popUpComponentData.vendorName}</div>
                  <div>{popUpComponentData.vendorCategory}</div>
                  <div className="flex flex-row items-center font-theme_secondary_light">
                    <RatingComponent />
                    <div>19</div>
                  </div>
                  <button className="capitalize text-white bg-theme_secondary hover:bg-theme_primary rounded text-center px-4 py-1">
                    Request Pricing
                  </button>
                </div>
              ) : (
                <div>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </div>
              )}
            </Popup>
          </Marker>
        ) : settable && dataType === undefined ? (
          <LocationMarker
            defaultPosition={
              position
                ? position
                : {
                    lat: mapPositionFromCache.LatLng.lat,
                    lng: mapPositionFromCache.LatLng.lng,
                  }
            }
          />
        ) : (
          <></>
        )}
      </MapContainer>
    </div>
  )
}

export default Map
