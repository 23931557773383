import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Customer } from "../../utils/types"

const initialState: Customer = {
  email: "",
  name: "",
  address: "",
  phone: "",
  about: "",
  profileImage: "",
  isActive: false,
}

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.name = action.payload.name
      state.email = action.payload.email
      state.phone = action.payload.phone
      state.address = action.payload.address
      state.about = action.payload.about
      state.profileImage = action.payload.profileImage
      state.isActive = action.payload.isActive
    },
  },
})

export const { setCustomer } = customerSlice.actions
export default customerSlice.reducer
