export const slides = [
  {
    source:
      "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/slides/t97uhc0sn40bybhnvtpb",
    loader: "/images/slides/slide-1-loader.png",
    headerText: "Find the Perfect Events Vendor",
    subHeaderText:
      "Search over 2500 events vendors with reviews, pricing, availability and more",
  },
  {
    source: `https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/slides/igkqosxclac9gldrbn7c`,
    loader: "/images/slides/slide-2-loader.jpg",
    headerText: "Find the Perfect Events Vendor",
    subHeaderText:
      "Search over 2500 events vendors with reviews, pricing, availability and more",
  },
  {
    source: `https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/slides/vmbg2hrpvodpfhutxdp8`,
    loader: "/images/slides/slide-3-loader.jpg",
    headerText: "Find the Perfect Events Vendor",
    subHeaderText:
      "Search over 2500 events vendors with reviews, pricing, availability and more",
  },
]
