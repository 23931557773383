// src/services/places/api.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const placesApi = createApi({
  reducerPath: "placesApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://nominatim.openstreetmap.org" }),
  endpoints: (builder) => ({
    searchPlaces: builder.query<Array<any>, string>({
      query: (query) => `search?q=${encodeURIComponent(query)}&format=json`,
    }),
  }),
})

export const { useSearchPlacesQuery } = placesApi
