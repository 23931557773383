import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query"
import { logout, setUser } from "./authSlice"
import { Mutex } from "async-mutex"

const mutex = new Mutex()
const baseUrl: string = import.meta.env.VITE_SERVER_URL_REMOTE
// const baseUrl: string = import.meta.env.VITE_SERVER_URL_LOCAL

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: "include",
})
const baseQuerywithReauth: BaseQueryFn<
  string | FetchArgs,
  any,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          "/token/refresh",
          api,
          extraOptions,
        )
        if (refreshResult.data) {
          api.dispatch(setUser(refreshResult.data as any))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
          window.location.href = "/login"
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export default baseQuerywithReauth
